import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import jssPreset from '@mui/styles/jssPreset';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import React from 'react';
import { Router } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import GoogleTagManager from 'src/components/GoogleTagManager';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import routes, { renderRoutes } from 'src/routes';
import { createTheme } from 'src/theme';
import { QueryClient, QueryClientProvider } from 'react-query';

import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/700.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

import AOS from 'aos';

import 'leaflet/dist/leaflet.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import 'aos/dist/aos.css';
import 'swiper/css/swiper.min.css';

import moment from 'moment';
import 'moment/min/locales';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

moment.locale('pt-br'); // set with i18n in future.

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  AOS.init({
    once: false,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out'
  });

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';
  const queryClient = new QueryClient();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <LocalizationProvider
            dateAdapter={DateAdapter}
            locale="pt-br"
            dateLibInstance={moment}
          >
            <SnackbarProvider dense maxSnack={3}>
              <GoogleOAuthProvider clientId={googleClientId}>
                <Router history={history}>
                  <AuthProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    <QueryClientProvider client={queryClient}>
                      {renderRoutes(routes)}
                    </QueryClientProvider>
                    <GoogleTagManager />
                  </AuthProvider>
                </Router>
              </GoogleOAuthProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
